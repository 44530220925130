import React, { Component, Fragment } from 'react'
import { Container } from 'react-bootstrap'

class Detail extends Component {
    render() {
        return (
            <Fragment>
                <section className="detail-area-about">
                    <Container>
                        <div className="section-heading mb-lg-30">
                            <img className="img-fluid" src="/logo/logo-ppc.svg" alt="logo permata puri cibubur" width="228" height="91"/>
                            <h1 className="title-heading">PERMATA PURI CIBUBUR TEWARKAN HUNIAN DENGAN KONSEP TROPICAL HOUSE</h1>
                        </div>
                        <div className="section-body">
                            <p>Mengadaptasi kondisi di era new normal saat ini, masyarakat semakin berfikir akan gaya hidup sehat dan nyaman, baik untuk kesehatan maupun tempat tinggal. Kenyamanan tempat tinggal sangat mempengaruhi kehidupan penghuninya, untuk selalu menyeimbangkan mind, body and soul. Untuk menyelaraskan kondisi saat ini, Permata Puri Cibubur menawarkan hunian rumah tapak dengan konsep Tropical House. Konsep ini di adopt sesuai dengan iklim di Indonesia.</p>
                            <p>Permata Puri Cibubur, Kawasan yang terletak di timur Jakarta dikembangkan oleh developer terkemuka yaitu PT PP Properti Tbk (“PPRO”) salah satu anak perusahaan BUMN Konstruksi dan Investasi terkemuka di Indonesia yaitu PT PP (Persero) Tbk (“PTPP”). PP Properti telah berpengalaman dalam mengelola Kawasan hunian tapak, berkiparah mulai dari tahun 1991 hingga saat ini, PPRO telah mengembangkan lebih dari 51 produk residensial, Mall & Edutaiment dan Hotel yang tersebar diseluruh Indonesia.</p>
                            <h2>LOKASI STRATEGI DENGAN KONSEP HUNIAN TROPIS MODERN</h2>
                            <p>Melirik kawasan Cibubur dan sekitarnya yang terus berkembang, membuat lokasi ini masih menjadi pilihan favorit bagi mereka yang mencari hunian nyaman yang lengkap. Mulai dari kehadiran akses Tol JORR 2 (exit Jatikarya 2) hingga LRT yang akan menghubungkan kawasan Selatan dan Timur Jakarta hingga ke pusat kota hanya dalam hitungan menit. Lengkapnya jalur konektivitas hingga maraknya kehadiran kawasan komersial, seperti mall dan pusat perbelanjaan dengan konsep one-stop entertainment, menambah nyamannya tinggal di kawasan Cibubur ini.</p>
                            <p>Berlokasi di Jalan Trans Yogi, Permata Puri Cibubur merupakan perumahan cluster yang menghadirkan kesan private dan cozy, dimana elemen bangunan yang menyatu dengan alam, pemilihan warna serta material bangunan yang berkesan down to earth. Permata Puri Cibubur memberikan nuansa gaya hidup urban living yang akan memanjakan anda dan keluarga. Tidak tanggung-tanggung, PP Properti mempercayakan Andra Matin, yang dikenal sebagai arsitek dengan gaya tropis modern. Memadukan unsur modern dan unsur alam dalam karya-karyanya menjadi tampak “modern” dan “clean”.</p>
                            <p>Kawasan Permata Puri Cibubur memberikan kebutuhan hunian yang nyaman, mulai dari konsep pengaturan ruang yang disesuaikan dengan kebutuhan dan kebiasaan hidup penghuninya. Sedikit menggeser kebiasaan dengan memindahkan ruang service yang biasanya berada di belakang bagian bangunan menjadi berada di bagian depan bangunan. Konsep pemindahan ruang services tersebut lagi-lagi mengadaptasi dari kondisi pandemic saat ini, untuk meminimalisirkan kontak dengan orang lain saat penghuni butuh mengganti utilisasi bangunan seperti AC, Gas, mesin cuci atau lainnya.</p>
                            <div className="detail-img">
                                <img className="img-fluid" src="/logo/logo-pp.svg" alt="logo pp properti" width="70" height="86" />
                            </div>
                            <p>PT PP Properti Tbk didirikan Tahun 2013 sesuai Akta Pendirian Perseroan Terbatas No.18 tanggal 12 Desember 2013 dibuat dihadapan Ir. Nanetter Cahyanie Handari Adi Warsito, S.H. yang telah mendapatkan pengesahan dari Menteri Hukum dan Hak Asasi Manusia Nomor AHU-04852.AH.01.01.Tahun 2014 tanggal 5 Februari 2014 ("PP Properti") yang merupakan pemisahan divisi properti dari PT PP (Persero) Tbk yang telah ada sejak tahun 1991.</p>
                            <p>PT PP Properti Tbk mengintegrasikan pengembangan yang didukung dengan adanya fasilitas yang beragam dan lengkap sehingga menjadikan penghuninya berkembang sehat lahir batin. Lebih dari 20 (dua puluh) tahun PT PP Properti Tbk (yang merupakan pemisahan dari Divisi Properti dari PT PP (Persero) Tbk) telah mengembangkan kemampuannya di segala bidang, antara lain membangun Perumahan Otorita Jatiluhur tahun 1991, pengembangan kawasan Cibubur tahun 1991 - 2004, Apartemen Paladin Park di Kelapa Gading, Juanda business center di Surabaya dan pembangunan serta pengelolaan Park Hotel Jakarta tahun 2010 dan Park Hotel Bandung tahun 2012.</p>
                            <p>PT PP Properti Tbk memiliki 3 (tiga) unit Bisnis, yaitu Komersial, Residensial dan Hotel. PT PP Properti Tbk berkomitmen untuk terus mengembangkan bisnisnya dengan mengkombinasikan segala pengetahuan dan kemampuan karyawannya, dari segi desain maupun kualitas pembangunan yang semakin baik, dan juga berkomitmen kepada pelanggan serta pemasoknya.</p>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Detail;