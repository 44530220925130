import React, { Component, Fragment } from 'react'
import { Container, Row, Col} from 'react-bootstrap'

class Stories extends Component {
    render() {
        return (
            <Fragment>
                <section className="home-stories-area">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} lg={6} md={12} sm={12} className="mr-auto mb-4 mb-lg-0">
                                <div className="img-stories">
                                    <img className="img-fluid" src="/img/home/own.png" alt="" width="608" height="294"/>
                                </div>
                            </Col>
                            <Col xs={12} lg={6} md={12} sm={12} className="ml-auto">
                                <h3 className="">Andra Matin</h3>
                                <span>Principal Architect</span>
                                <p className="pt-3 pt-lg-4">"Dikenal dengan rancangannya yang bernuansa modern dan sadar akan fungsi setiap ruang, Andra Matin memulai firma arsitekturnya pada tahun 1998. Sebagai pemenang Indonesia Architect Association Award, Andra dikenal sebagai arsitek visioner, dengan beragam jenis karya, diantaranya: Katamama Hotel, Gedung Dua8, Komunitas Salihara, Tanah Teduh dan lain-lain."</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Stories;