import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import ReactWhatsapp from 'react-whatsapp'
import axios from 'axios'

class Hero extends Component {
    state = {
        listBanner: [],
        textBanner: [],
    }

    componentDidMount(){
        this.loadListBanner()
        this.loadTextBanner()
    }

    loadListBanner = () => {
        const url = process.env.REACT_APP_API + `banner`;
        axios.get(url)
        .then(res => {
            const listBanner = res.data;
            this.setState({listBanner});
        })
    }

    loadTextBanner = () => {
        const url = process.env.REACT_APP_API + `textBanner`;
        axios.get(url)
        .then(res => {
            const textBanner = res.data;
            this.setState({textBanner});
        })
    }

    render() {
        const options = {
            loop: false,
            dots: true,
            nav: false,
            autoplay: true,
            smartSpeed: 1250,
            items: 1
        }
        const txtBanner = this.state.textBanner;
        return (
            <Fragment>
                <section className="home-hero-area m-0">
                    {
                        this.state.listBanner.length && (
                            <OwlCarousel className="owl-theme" {...options}>
                                {this.state.listBanner.map((event, i) => {
                                    return(
                                        <div className="item" style={{backgroundImage: `url(${event.link_image})`}}  key={i}/>
                                    );
                                })}
                            </OwlCarousel>
                        )
                    }
                    <div className="overlay">
                        <div className="hero-content wow fadeIn" data-wow-delay=".5s">
                            <Container>
                                <Row className="align-items-center justify-content-center d-flex">
                                    <Col md={8} className="text-center">
                                        <h1>
                                            {txtBanner.textbanner}
                                        </h1>
                                        <div className="mt-2 mt-lg-3">
                                            <ReactWhatsapp number="+6282133100075" className="btn btn-hero m-1 m-lg-3 active">
                                                Booking Now
                                            </ReactWhatsapp>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Hero;