import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
// common
import Header from './components/common/Header'
import Footer from './components/common/Footer'
// pages
import Home from './components/pages/Home'
import About from './components/pages/About'
import TypeUnit4584 from './components/pages/TypeUnit4584'
import TypeUnit7284 from './components/pages/TypeUnit7284'
import TypeUnit10590 from './components/pages/TypeUnit10590'
import View360 from './components/pages/View360'
import MasterPlan from './components/pages/MasterPlan'
import News from './components/pages/News'
import NewsDetail from './components/pages/NewsDetail'
import Gallery from './components/pages/Gallery'
import Contact from './components/pages/Contact'

import ScrollTop from './ScrollTop'
require('dotenv').config()

class App extends Component {
  render() {
    return (
      <Fragment>
        <ScrollTop>
          <Header/>
          <div className="wrapper">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/type-unit/type-45-84" component={TypeUnit4584}/>
              <Route exact path="/type-unit/type-72-84" component={TypeUnit7284}/>
              <Route exact path="/type-unit/type-105-90" component={TypeUnit10590}/>
              <Route exact path="/360-view" component={View360}/>
              <Route exact path="/master-plan" component={MasterPlan}/>
              <Route exact path="/news" component={News}/>
              <Route exact path="/news/:slug" component={NewsDetail} />
              <Route exact path="/gallery" component={Gallery}/>
              <Route exact path="/contact-us" component={Contact} />
            </Switch>
          </div>
          <Footer/>
        </ScrollTop>
      </Fragment>
    );
  }
}

export default App;