import React, { Component, Fragment } from 'react'
import ReactWhatsapp from 'react-whatsapp'

class WhatsApp extends Component {
    render() {
        return (
            <Fragment>
                <ReactWhatsapp number="+6282133100075" className="float">
                    <img className="img-fluid" src="/logo/icon-whatsapp.png" alt="Logo WhatsApp" width="100" height="100"/>
                </ReactWhatsapp>
            </Fragment>
        );
    }
}

export default WhatsApp;