import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class StratgicLocation extends Component {
    render() {
        return (
            <Fragment>
                <section className="home-strategiclocation-area">
                    <Container>
                        <div className="section-heading">
                            <h2 className="title-heading">Strategic Location</h2>
                        </div>
                        <div className="section-body">
                            <Row>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard1.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard2.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard3.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard4.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard5.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard6.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard7.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard8.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard9.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard10.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard11.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} md={6} sm={12}>
                                    <div className="sl-box">
                                        <div className="sl-image">
                                            <img className="img-fluid" src="/img/home/Artboard12.png" alt="" width="295" height="300" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default StratgicLocation;