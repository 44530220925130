import React, { Component, Fragment } from 'react'
import { Container, Row, Col} from 'react-bootstrap'

class Footer extends Component {
    render() {
        return (
            <Fragment>
                <footer>
                    <section className="footer-top">
                        <Container>
                            <Row>
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <div className="footer-about text-center">
                                        <img className="img-fluid" src="/logo/logo-ppc.svg" alt="logo permata puri cibubur" width="228" height="91" />
                                        <p>Permata Puri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti.</p>
                                    </div>
                                    <ul className="list-unstyled footer-sosial-media">
                                        <li><a href="https://www.facebook.com/permatapuricibubur" target="_blank" rel="noreferrer"><img className="img-fluid" src="/icon/icon-fb.svg" alt="icon sosial media facebook" width="34" height="34" /><span>Permata puri cibubur</span></a></li>
                                        <li><a href="https://www.instagram.com/permatapuricibubur/" target="_blank" rel="noreferrer"><img className="img-fluid" src="/icon/icon-instagram.svg" alt="icon sosial media instagram" width="32" height="31" /><span>Permata puri cibubur</span></a></li>
                                    </ul>
                                </Col>
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <div className="footer-information mt-lg-0 mt-5">
                                        <h2>INFORMATION</h2>
                                        <ul className="list-unstyled text-left">
                                            <li><a href="/about">About Us</a></li>
                                            <li><a href="/type-unit/type-45-84">Type Unit</a></li>
                                            <li><a href="/360-view">360 View</a></li>
                                            <li><a href="/master-plan">Master Plan</a></li>
                                            <li><a href="/news">News</a></li>
                                            <li><a href="/gallery">Gallery</a></li>
                                            <li><a href="/contact-us">Contact Us</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} lg={4} md={12} sm={12}>
                                    <div className="footer-visit mt-lg-0 mt-5">
                                        <h2>VISIT</h2>
                                        <div className="address">
                                            <p>Jl. Kav. No. 70, Harjamukti, Kec. Cimanggis, Kota Depok, Jawa Barat 16454</p>
                                            <ul className="list-unstyled contact">
                                                <li><img className="img-fluid" src="/icon/icon-phone.svg" alt="icon phone" width="31" height="31" /><span>021 - 87792734</span></li>
                                                <li><img className="img-fluid" src="/icon/icon-email.svg" alt="icon email" width="31" height="28" /><span>permatapuricibubur@gmail.com</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="footer-bottom">
                        <div className="p-2 d-flex align-items-center justify-content-center text-center">
                            <p>Permata Puri Cibubur © Copyright 2021 - Powered by <a href="https://imajinative.agency/" target="_blank" rel="noreferrer" >imajinative</a></p>
                        </div>
                    </section>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;