import React, { Component, Fragment } from 'react'
import { Container } from 'react-bootstrap'

class Strategic extends Component {
    render() {
        return (
            <Fragment>
                <section className="stategic-area">
                    <div className="section-heading">
                        <h2 className="title-heading">Strategic Location</h2>
                    </div>
                    <Container>
                        <div className="list-strategic-location">
                            <ul className="list-unstyled">
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>4 menit Menuju Trans Studio Cibubur</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>4 menit Menuju Rumah Sakit Melia Hospital</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>4 menit Menuju Rumah Sakit Permata Cibubur</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>5 menit Menuju Pintu Toll Cibubur</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>5 menit Menuju Pintu Toll Jatikarya ll</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>5 menit Menuju Loka Supermarket Cibubur</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>5 menit Menuju Mc Donald’s</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>10 menit Menuju Mall Cibubur Junction</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>10 menit Menuju Wisata Taman Wiladika</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>10 menit Menuju Bumi Perkemahan Cibubur (Buperta)</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>10 menit Menuju Stasiun LRT</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src="../img/typeunit/4584/check.png" width="23" height="23" alt="location check" />
                                    <span>10 menit Menuju Shuttle Bus Transjakarta</span>
                                </li>
                            </ul>
                        </div>
                        <div className="img-lokasi text-center my-5">
                            <img className="img-fluid" src="../img/typeunit/4584/peta-lokasi.png" width="983" height="777" alt="peta lokasi"/>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Strategic;