import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'

class About extends Component {
    render() {
        return (
            <Fragment>
                <section className="home-about-area">
                    <Container>
                        <div className="section-heading">
                            <h2 className="title-heading">Welcome</h2>
                        </div>
                        <div className="section-body">
                            <Row className="align-items-lg-center justify-content-lg-center">
                                <Col lg={6} md={12} sm={12} className="order-2 order-lg-1">
                                    <h3 className="title-body">Tropical House at Cibubur</h3>
                                    <p className="content-body">
                                        Permata Putri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti, Hunian ini sangat cocok dimiliki karena berkualitas tinggi, harga juga kompetitif. Memiliki konsep yang menyatu dengan alam, lingkungan yang asri serta udara yang segar. Membuat Permata Putri Cibubur menjadi oase di tengah kota.
                                    </p>
                                </Col>
                                <Col lg={6} md={12} sm={12} className="order-1 order-lg-2 mb-4 mb-lg-0">
                                    <div className="about-img text-center">
                                        <ReactPlayer url="/video/home_video.mp4" muted={false} playing={true} controls loop={true}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default About;