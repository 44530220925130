import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// content
import Hero from '../content/about/Hero'
import Detail from '../content/about/Detail'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class About extends Component {
    render() {
        const desc= "Permata Puri Cibubur Tawarkan Hunian dengan Konsep Tropical House, Melirik kawasan Cibubur dan sekitarnya yang terus berkembang, membuat lokasi ini masih menjadi pilihan favorit bagi mereka yang mencari hunian nyaman yang lengkap.";
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="/about" />
                    <link rel='shortlink' href='/about' />
                    {/* Primary Meta Tags */}
                    <title>About Us - Permata Puri Cibubur</title>
                    <meta name="title" content="About Us - Permata Puri Cibubur"/>
                    <meta name="description" content={desc.slice(0, 170)}/>
                    <meta name="keywords" content="Permata puri cibubur, beli rumah di cibubur, Andra matin, PP Properti, project pp properti, perumahan di cibubur, rumah di jual cibubur"/>

                    <meta itemprop="title" content="About Us - Permata Puri Cibubur"/>
                    <meta itemprop="description" content={desc.slice(0, 170)}/>
                    <meta itemprop="image" content="/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="/about"/>
                    <meta property="og:title" content="About Us - Permata Puri Cibubur"/>
                    <meta property="og:description" content={desc.slice(0, 170)}/>
                    <meta property="og:image" content="/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="/about"/>
                    <meta property="twitter:title" content="About Us - Permata Puri Cibubur"/>
                    <meta property="twitter:description" content={desc.slice(0, 170)}/>
                    <meta property="twitter:image" content="/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Hero/>
                <Detail/>
            </Fragment>
        );
    }
}

export default About;