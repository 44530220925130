import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// contact
import Hero from '../content/contact/Hero'
import Detail from '../content/contact/Detail'
import Maps from '../content/contact/Maps'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class Contact extends Component {
    render() {
        const desc = "Permata Puri Cibubur - tentukan pilihan hunian nyaman anda di Permata Puri Cibubur, hubungi kami untuk mendapatkan nomor urut pembelian dan untuk mendapatkan info selengkapnya."
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="/contact-us" />
                    <link rel='shortlink' href='/contact-us' />
                    {/* Primary Meta Tags */}
                    <title>Contact Us - Permata Puri Cibubur</title>
                    <meta name="title" content="Contact Us - Permata Puri Cibubur"/>
                    <meta name="description" content={desc.slice(0, 170)}/>
                    <meta name="keywords" content="Permata puri cibubur pp properti, permata puri cibubur tropical house, proyek pt pp properti, dijual rumah di cibubur, perumahan di cibubur"/>

                    <meta itemprop="title" content="Contact Us - Permata Puri Cibubur"/>
                    <meta itemprop="description" content={desc.slice(0, 170)}/>
                    <meta itemprop="image" content="/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="/contact-us"/>
                    <meta property="og:title" content="Contact Us - Permata Puri Cibubur"/>
                    <meta property="og:description" content={desc.slice(0, 170)}/>
                    <meta property="og:image" content="/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="/contact-us"/>
                    <meta property="twitter:title" content="Contact Us - Permata Puri Cibubur"/>
                    <meta property="twitter:description" content={desc.slice(0, 170)}/>
                    <meta property="twitter:image" content="/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Hero/>
                <Detail/>
                <Maps/>
            </Fragment>
        );
    }
}

export default Contact;