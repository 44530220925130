import React, { Fragment, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactBnbGallery from 'react-bnb-gallery'

const photoMasterPlan = [
    {
        "photo": '/img/masterplan/Master_plan_terbaru.png'
    }
]

function Detail() {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <section className="masterplan-area">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            {
                                photoMasterPlan.map((val, i) => {
                                    return(
                                        <img className="img-fluid" src={val.photo} alt="paln" onClick={() => setOpen(true)} key={i} />
                                    );
                                })
                            }
                        </Col>
                    </Row>
                    <ReactBnbGallery
                        show={open}
                        photos={photoMasterPlan}
                        onClose={() => setOpen(false)}
                    />
                </Container>
            </section>
        </Fragment>
    );
}

export default Detail;