import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import Iframe from 'react-iframe'
import ReactPlayer from 'react-player'

class Detail extends Component {
    render() {
        return (
            <Fragment>
                <section className="detail-area-360view">
                    <Container>
                        <Row>
                            <Col>
                                <ReactPlayer className="videodokumtasi" url="https://thumbs.dreamstime.com/videothumb_large13696/136960894.mp4" muted={true} playing={true} controls loop={true}/>
                                {/* <Iframe className="view-360-display" style="width: 100%; height: 100%" tabIndex="0" allowFullScreen={true} aria-hidden="false" style={{border: "0"}} frameBorder="0" src="https://thumbs.dreamstime.com/videothumb_large13696/136960894.mp4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"/> */}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Detail;