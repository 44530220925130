import React, { Fragment, useEffect } from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'

function Header() {
    const isSticky = (e) => {
		const header = document.querySelector("header");
		const scrollTop = window.scrollY;
		scrollTop >= 10
			? header.classList.add("sticky")
			: header.classList.remove("sticky");
	};

	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
    });

    const toggleClass = () => {
        const icon_bar = document.querySelector('#icon_bars');
        icon_bar.classList.toggle('fa-times');
    }
    return (
        <Fragment>
            <header id="header">
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Toggle onClick={toggleClass}><i className="fas fa-bars" id="icon_bars"></i></Navbar.Toggle>
                        <Navbar.Brand href="/" alt="Permata Puri Cibubur">
                            <img className="img-fluid" src="/logo/logo-ppc.svg" alt="logo permata puri cibubur" width="200" height="37" />
                        </Navbar.Brand>
                        <Navbar.Collapse className="justify-content-center">
                            <Nav as="ul" id="navigation">
                                <Nav.Item as="li">
                                    <Nav.Link href="/about" className="nav-link" title="About Us">About</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="Type Unit" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/type-unit/type-45-84">Type 45/84</NavDropdown.Item>
                                    <NavDropdown.Item href="/type-unit/type-72-84">Type 72/84</NavDropdown.Item>
                                    <NavDropdown.Item href="/type-unit/type-105-90">Type 105/90</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item as="li">
                                    <Nav.Link href="/360-view" className="nav-link">360 View</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/master-plan" className="nav-link">Master Plan</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/news" className="nav-link">News</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/gallery" className="nav-link">Gallery</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/contact-us" className="nav-link">Contact Us</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="d-none d-lg-block img-pp">
                            <img className="img-fluid" src="/logo/logo-pp.svg" alt="logo permata puri cibubur" width="69" height="86"/>
                        </div>
                    </Container>
                </Navbar>
            </header>
        </Fragment>
    );
}

export default Header;