import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// content
import Hero from '../content/typeunit/7284/Hero'
import Visual from '../content/typeunit/7284/Visual'
import Facilites from '../content/typeunit/7284/Facilites'
import Strategic from '../content/typeunit/7284/Strategic'
import Simulasi from '../content/typeunit/7284/Simulasi'
import OtherUnit from '../content/typeunit/7284/OtherUnit'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class TypeUnit7284 extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="/type-unit/type-72-84" />
                    <link rel='shortlink' href='/type-unit/type-72-84' />
                    {/* Primary Meta Tags */}
                    <title>Type unit 72/84 - Permata Puri Cibubur</title>
                    <meta name="title" content="Type unit 72/84 - Permata Puri Cibubur"/>
                    <meta name="description" content="Permata Puri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti."/>
                    <meta name="keywords" content="type unit 72, permata puri cibubur, pp properti, rumah strategis di cibubur, tropical house, rumah di jual di cibubur"/>

                    <meta itemprop="title" content="Type unit 72/84 - Permata Puri Cibubur"/>
                    <meta itemprop="description" content="Permata Puri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti."/>
                    <meta itemprop="image" content="/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="/type-unit/type-72-84"/>
                    <meta property="og:title" content="Type unit 72/84 - Permata Puri Cibubur"/>
                    <meta property="og:description" content="Permata Puri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti."/>
                    <meta property="og:image" content="/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="/type-unit/type-72-84"/>
                    <meta property="twitter:title" content="Type unit 72/84 - Permata Puri Cibubur"/>
                    <meta property="twitter:description" content="Permata Puri Cibubur merupakan hunian bernuansa tropical di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti."/>
                    <meta property="twitter:image" content="/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Hero/>
                <Visual/>
                <Facilites/>
                <Strategic/>
                <Simulasi/>
                <OtherUnit/>
            </Fragment>
        );
    }
}

export default TypeUnit7284;