import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'

class Visual extends Component {
    render() {
        return (
            <Fragment>
                <section className="visual-area">
                    <Container>
                        <Row className="align-items-center justify-content-center text-center">
                            <Col lg={6}>
                                <img className="img-fluid" src="../img/typeunit/4584/45Lt1.png" width="417" height="710" alt="type unit 45/84 lantai 1" />
                            </Col>
                            <Col lg={6} className="mt-lg-0 mt-3">
                                <img className="img-fluid" src="../img/typeunit/4584/45Lt2.png" width="417" height="710" alt="type unit 45/84 lantai 2" />
                            </Col>
                            <Col xs={12} lg={6} className="mt-4 mt-lg-5 text-center">
                                <div className="video-frame">
                                    <ReactPlayer url="/video/Type4586.mp4" muted={false} playing={true} controls loop={true}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="visual-desc">
                    <Container>
                        <div className="list-desc">
                            <Row className="align-items-center justify-content-center">
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0 text-center">
                                    <img className="img-fluid" src="../img/typeunit/visual/kamar-mandi.png" width="41" height="45" alt="icon kamar mandi" />
                                    <p>Kamar Mandi</p>
                                    <span>1</span>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0 text-center">
                                    <img className="img-fluid" src="../img/typeunit/visual/garasi.png" width="41" height="46" alt="icon garasi" />
                                    <p>Garasi</p>
                                    <span>1</span>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0 text-center">
                                    <img className="img-fluid" src="../img/typeunit/visual/kamar-tidur.png" width="41" height="41" alt="icon kamar tidur" />
                                    <p>Kamar Tidur</p>
                                    <span>2</span>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0 text-center">
                                    <img className="img-fluid" src="../img/typeunit/visual/luas-tanah.png" width="41" height="45" alt="icon luas tahan" />
                                    <p>Luas Tanah</p>
                                    <span>84 m2</span>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0 text-center">
                                    <img className="img-fluid" src="../img/typeunit/visual/luas-bangunan.png" width="41" height="45" alt="icon luas bangunan" />
                                    <p>Luas Bangunan</p>
                                    <span>45 m2</span>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Visual;